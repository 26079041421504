<template>
	<div id='noticeList'>
		<!-- 标题 -->
		<div class="labelBox">
			<div class="title">公告管理</div>
			<div>
				<el-button type="primary" size="medium" @click="add">添加公告</el-button>
			</div>
		</div>

		<!-- 公告列表 -->
		<el-table :data="list" style="width: 100%">
			<el-table-column prop="id" label="ID" width="50">
			</el-table-column>
			<el-table-column prop="title" label="公告标题" width="300">
			</el-table-column>
			<el-table-column prop="content" label="公告内容" width="400">
			</el-table-column>
			<el-table-column prop="miniAppName" label="所属小程序">
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<el-tag type="success" size="small" v-if="scope.row.isShow === 1">展示</el-tag>
					<el-tag type="danger" size="small" v-else>隐藏</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="添加时间">
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
					<el-divider direction="vertical"></el-divider>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)">
						<el-button type="text" slot="reference" style="color:#F56C6C">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>

		<!-- 添加公告弹窗 -->
		<el-dialog :title="editTitle" :visible.sync="editModal" width="50%">
			<div class="modalBox">
				<el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px" class="demo-ruleForm">
					<el-form-item label="公告标题" prop="title">
						<el-input v-model="editForm.title"></el-input>
					</el-form-item>
					<el-form-item label="公告内容" prop="content">
						<el-input v-model="editForm.content" type="textarea" :rows="10"></el-input>
					</el-form-item>
					<el-form-item label="所属小程序" prop="miniAppId">
						<el-select v-model="editForm.miniAppId" placeholder="请选择">
							<el-option v-for="item in miniList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否展示" prop="isShow">
						<el-radio v-model="editForm.isShow" :label="1">展示</el-radio>
						<el-radio v-model="editForm.isShow" :label="0">隐藏</el-radio>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editModal = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">保 存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'noticeList',
		data() {
			return {
				id: '',
				list: [],
				changeData: [],
				miniList: [],
				editModal: false,
				editTitle: '',
				editForm: {
					name: '',
					appid: '',
					portId: ''
				},
				rules: {
					title: [{
						required: true,
						message: '请填写公告标题',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请填写公告内容',
						trigger: 'blur'
					}],
					miniAppId: [{
						required: true,
						message: '请选择所属小程序',
						trigger: 'change'
					}],
					isShow: [{
						required: true,
						message: '请选择是否开启',
						trigger: 'change'
					}],
				}
			}
		},

		created() {
			this.getList()
			this.getMiniList()
		},

		methods: {
			getMiniList() {
				this.$http.post('/miniConfig/list', {}).then(res => {
					if (res.data.code == 1) {
						this.miniList = res.data.data
					}
				})
			},

			add() {
				this.editForm = {}
				this.id = ''
				this.editTitle = '添加公告'
				this.editModal = true
			},

			edit(id) {
				this.id = id
				this.editTitle = '编辑公告'
				this.$http.post('/notice/noticeView', {
					id: this.id
				}).then(res => {
					if (res.data.code == 1) {
						this.editForm = res.data.data
						this.editModal = true
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			submitForm() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						var data = {
							aid: window.localStorage.aid,
							type: window.localStorage.type,
							title: this.editForm.title,
							content: this.editForm.content,
							miniAppId: this.editForm.miniAppId,
							isShow: this.editForm.isShow
						}
						if (this.id) {
							data.id = this.id
						}
						this.$http.post('/notice/noticeEdit', data).then(res => {
							if (res.data.code == 1) {
								this.editModal = false
								this.getList()
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						})
					}
				});
			},

			del(id) {
				this.$http.post('/task/del', {
					id: id
				}).then(res => {
					if (res.data.code == 1) {
						this.getList()
						this.$message.success(res.data.msg)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			getList() {
				this.$http.post('/notice/noticeList', {
					aid: window.localStorage.aid,
					type: window.localStorage.type
				}).then(res => {
					if (res.data.code == 1) {
						this.list = res.data.data
					}
				})
			}
		}
	}
</script>

<style scoped>
	#noticeList {
		padding: 30px;
		background: #fff;
	}

	.modalBox {
		padding-right: 100px;
		/* overflow:scroll; */
		overflow-y: auto
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: auto;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: auto;
		display: block;
	}

	.paginationBox {
		margin: 20px 0;
		text-align: right;
	}
</style>
